

export const startCountdown = (component) => {
	if (component.state.userAlreadyExists && component.state.timeout > 0 && !component.timer) {
		component.timer = setInterval(() => {
			component.setState((prevState) => {
				if (prevState.timeout > 0) {
					return { timeout: prevState.timeout - 1 };
				} else {
					window.location.href = '/';
					clearInterval(component.timer);
					return null;
				}
			});
		}, 1000);
	}
};

export const checkUserExistence = async (attributes, token) => {
	try {
		const headers = {
			'Content-Type': 'application/json',
			'X-CSRF-Token': token
		}
		const response = await fetch('/api/v1/users/check_customer', {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(attributes)
		})

		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		const json = await response.json();

		if (json.status === 'exists') return true;

	} catch (error) {
		console.error(error.message);
		return false;
	}

	return false;
}
